<template>
  <div v-loading="loading" class="main-container">
    <div class="dashboard" v-if="activeCustomDashboard">
      <div class="Greeting">
        <h2 class="fw-normal mb-3 blue-dark fs-5">
          {{ activeCustomDashboard.name }}
        </h2>
      </div>
      <div v-if="activeCustomDashboard">
        <el-row
          class="dashboard-filters"
          v-if="
            activeCustomDashboard &&
            ((activeCustomDashboard.allCustomFilters &&
              activeCustomDashboard.allCustomFilters.length) ||
              activeCustomDashboard.includeGlobalDateFilter)
          "
        >
          <el-col
            :span="24"
            style="border-bottom: #d2dcfa solid 1px; margin-bottom: 5px"
          >
            <CustomDashboardFilters
              :allDashboardstats="activeCustomDashboard"
              :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
            ></CustomDashboardFilters>
          </el-col>
        </el-row>
        <el-row
          v-if="
            activeCustomDashboard &&
            activeCustomDashboard.allStatComponents &&
            activeCustomDashboard.allStatComponents.length
          "
        >
          <el-col :span="24" style="margin-top: 10px">
            <CustomDashboardStats
              :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
              :allDashboardstats="activeCustomDashboard"
              :hide_options="true"
              :isFromApplicationuser="isFromApplicationuser"
              :refresh="refresh"
              :permissionsSet="permissionsSet"
            >
            </CustomDashboardStats>
          </el-col>
        </el-row>
        <hr
          v-if="
            activeCustomDashboard &&
            activeCustomDashboard.allStatComponents &&
            activeCustomDashboard.allStatComponents.length
          "
        />
        <el-row v-if="activeCustomDashboard" :gutter="25">
          <div
            v-if="
              !activeCustomDashboard.allTableComponents.length &&
              !hasDataTableStat
            "
          >
            <el-empty description="No tables configured"></el-empty>
          </div>
          <!-- :span="index == expendedIndex || getIsMobile ? 24 : 12" -->
          <el-scrollbar wrap-style="height: calc(100vh - 100px) !important;">
            <div class="wrapper-grid">
              <draggable
                class="drag-grid"
                @end="switchPositions"
                :disabled="!activeCustomDashboard.allowUserChangeOrder"
              >
                <div
                  v-for="(
                    component, index
                  ) in activeCustomDashboard.allTableComponents"
                  :key="
                    component.entity_id +
                    '_' +
                    component.keyValue +
                    '_table_' +
                    index
                  "
                  class="grid-item"
                  :style="
                    expendedIndexs.indexOf(index) != -1 || getIsMobile
                      ? 'grid-column: 1/3;'
                      : ''
                  "
                >
                  <CustomDashboardTable
                    v-if="component.type == 'TABLE'"
                    :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                    :component="component"
                    :entity_id="component.entity_id"
                    :filter_id="component.filter_id"
                    :index="index"
                    :globalVariablesData="globalVariablesData"
                    @expendTable="expendTable"
                    :hide_options="true"
                    :permissionsSet="permissionsSet"
                    :hasViewPermission="
                      checkPermissions(component.entity_id, 'VIEW')
                    "
                    :hasEditPermission="
                      checkPermissions(component.entity_id, 'EDIT')
                    "
                    :hasAddPermission="
                      checkPermissions(component.entity_id, 'ADD')
                    "
                    :isFromApplicationuser="isFromApplicationuser"
                  ></CustomDashboardTable>
                  <CustomDashboardPieChart
                    v-else-if="component.type == 'CHART'"
                    :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                    :component="component"
                    :hide_options="true"
                    :index="index"
                    :isFromApplicationuser="isFromApplicationuser"
                    @expendTable="expendTable"
                    :hasViewPermission="
                      checkPermissions(component.entity_id, 'VIEW')
                    "
                    :hasEditPermission="
                      checkPermissions(component.entity_id, 'EDIT')
                    "
                  ></CustomDashboardPieChart>
                  <CustomDashboardCalendar
                    v-else-if="component.type == 'CALENDAR'"
                    :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                    :component="component"
                    :index="index"
                    @expendTable="expendTable"
                    :hide_options="true"
                    :isFromApplicationuser="isFromApplicationuser"
                  >
                  </CustomDashboardCalendar>
                  <CustomDashboardLogins
                    v-else-if="component.type == 'LOGINS'"
                    :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                    :component="component"
                    :index="index"
                    @expendTable="expendTable"
                    :hide_options="true"
                    :isFromApplicationuser="isFromApplicationuser"
                  ></CustomDashboardLogins>
                  <EntityViews
                    v-else-if="component.type == 'ENTITY_VIEW'"
                    :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                    :entityViewId="component.entity_view_id"
                    :fromCustomDashboard="true"
                    :index="index"
                    :component="component"
                    :hide_options="true"
                    @expendTable="expendTable"
                  >
                  </EntityViews>
                  <CustomDashboardGaugeChart
                    v-else-if="component.type == 'GAUGE_CHART'"
                    :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                    :component="component"
                    :index="index"
                    :hide_options="true"
                    @expendTable="expendTable"
                  >
                  </CustomDashboardGaugeChart>
                  <CustomDashboardNotifications
                    v-else-if="component.type == 'NOTIFICATIONS'"
                    :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                    :component="component"
                    :index="index"
                    :hide_options="true"
                  >
                  </CustomDashboardNotifications>
                </div>
              </draggable>
            </div>
          </el-scrollbar>
        </el-row>
      </div>
      <div v-else>
        <el-empty description="No components configured"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import draggable from "vuedraggable";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
export default {
  components: {
    // DocumentsList,
    CustomDashboardTable: () =>
      import("@/components/customDashboard/customDashboardTable.vue"),
    CustomDashboardStats: () =>
      import("@/components/customDashboard/customDashboardStats.vue"),
    CustomDashboardPieChart: () =>
      import("@/components/customDashboard/customDashboardPieChart.vue"),
    CustomDashboardFilters: () =>
      import("@/components/customDashboard/customDashboardFilters.vue"),
    CustomDashboardCalendar: () =>
      import("@/components/customDashboard/customDashboardCalendar.vue"),
    CustomDashboardLogins: () =>
      import("@/components/customDashboard/customDashboardLogins.vue"),
    EntityViews: () => import("@/components/entity/EntityViews.vue"),
    draggable,
    CustomDashboardGaugeChart: () =>
      import("@/components/customDashboard/customDashboardGaugeChart.vue"),
    CustomDashboardNotifications: () =>
      import("@/components/customDashboard/customDashboardNotifications.vue"),
  },
  data() {
    return {
      loading: false,
      globalVariablesData: [],
      activeCustomDashboard: null,
      activeCustomDashboardId: "",
      expendedIndexs: [],
      refresh: false,
      permissionsSet: {},
      isFromApplicationuser: false,
      getAllCompanyTemplatesData : {}
    };
  },
  mixins: [MobileRelatedHelper, templateBuilderHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
      "getActiveContactType",
    ]),
    ...mapGetters("customDashboard", [
      "getUserDashboardsList",
      "getEntityUserDashboardsList",
      "getSingleDashboardData",
    ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("applicationUsersPermission", ["getEntityPermission"]),
    ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
  },
  mounted() {
    if (this.$route.name == "application-user-page-dashboard") {
      this.isFromApplicationuser = true;
    }
    this.getNecessaryInfo();
  },
  methods: {
    getNecessaryInfo() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
      if (this.$route?.params?.dashboardId) {
        this.fetchDashboard(this.$route?.params?.dashboardId);

        this.getSubscriptionHistory();
      }
    },
    async getSubscriptionHistory() {
      try {
        let email = "info@esigns.io";
        await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", {
          email,
        });
        //this.getPlanExpireDetails();
      } catch (error) {
        console.log("getSubscriptionHistory", error);
      }
    },
    checkPermissions(entity, permission) {
      if (this.isFromApplicationuser) {
        if (
          this.permissionsSet &&
          Object.keys(this.permissionsSet).length > 0
        ) {
          if (
            this.permissionsSet[entity] &&
            this.permissionsSet[entity].indexOf(permission) != -1
          ) {
            return true;
          }
          return false;
        }
        return false;
      }
      return true;
    },
    switchPositions(evt) {
      if (
        evt &&
        typeof evt.oldIndex == "number" &&
        typeof evt.newIndex == "number" &&
        this.activeCustomDashboard.allTableComponents[evt.oldIndex]
      ) {
        let data = this.activeCustomDashboard.allTableComponents[evt.oldIndex];
        let data1 = this.activeCustomDashboard.allTableComponents[evt.newIndex];
        data.order = evt.newIndex;
        data1.order = evt.oldIndex;
        this.activeCustomDashboard.allTableComponents[evt.oldIndex] = {
          ...data,
        };
        this.activeCustomDashboard.allTableComponents[evt.newIndex] = {
          ...data1,
        };
      }
    },
    expendTable(index) {
      if (this.expendedIndexs.indexOf(index) == -1) {
        this.activeCustomDashboard.allTableComponents[index][
          "is_expand"
        ] = true;
        this.expendedIndexs.push(index);
      } else {
        this.expendedIndexs.splice(this.expendedIndexs.indexOf(index), 1);
        this.activeCustomDashboard.allTableComponents[index][
          "is_expand"
        ] = false;
      }
    },
    async fetchDashboard(id) {
      this.loading = true;
      const query = { get_all: true };
      let promises = [];
      promises.push(
        this.$store.dispatch("customDashboard/getDashboardById", id)
      );
      promises.push(
        this.$store.dispatch("globalVariables/fetchGlobalVariables", query)
      );
      if (this.getActiveContactType?.contact_type?._id) {
        promises.push(
          this.$store.dispatch(
            "applicationUsersPermission/fetchPermissionsEntityById",
            { id: this.getActiveContactType?.contact_type?._id }
          )
        );
      }
      await Promise.all(promises);
      if (this.getEntityPermission?._id) {
        (this.getEntityPermission.other_entities &&
        Object.keys(this.getEntityPermission.other_entities)
          ? Object.keys(this.getEntityPermission.other_entities)
          : []
        ).forEach((entity) => {
          if (!this.permissionsSet[entity]) {
            this.permissionsSet[entity] =
              this.getEntityPermission.other_entities[entity].permissions;
          }
        });
        (this.getEntityPermission.relational_entities &&
        Object.keys(this.getEntityPermission.relational_entities)
          ? Object.keys(this.getEntityPermission.relational_entities)
          : []
        ).forEach((entity) => {
          if (!this.permissionsSet[entity]) {
            this.permissionsSet[entity] =
              this.getEntityPermission.relational_entities[entity].permissions;
          }
        });
      }
      if (this.getAllGlobalVariables && this.getAllGlobalVariables.data) {
        this.globalVariablesData = [...this.getAllGlobalVariables.data];
      }
      // if (
      //   this.getEntityUserDashboardsList &&
      //   this.getEntityUserDashboardsList.length
      // ) {
      //   this.customDashboardsList = [];
      //   this.customDashboardsList = this.getEntityUserDashboardsList.filter(
      //     (e) => e.components.length
      //   );
      let response = await this.fetchAllTemplates();
      this.getAllCompanyTemplatesData = {data : response}
      //     this.changeCustomDashboard(0);
      // }
      if (this.getSingleDashboardData) {
        this.activeCustomDashboard = {
          name: this.getSingleDashboardData.name,
          includeGlobalDateFilter:
            this.getSingleDashboardData.include_global_date_filter,
          allTableComponents: this.getSingleDashboardData.components
            .filter((e) =>
              [
                "TABLE",
                "CHART",
                "CALENDAR",
                "LOGINS",
                "ENTITY_VIEW",
                "GAUGE_CHART",
                "NOTIFICATIONS",
              ].includes(e.type)
            )
            .sort((a, b) => (a.order < b.order ? -1 : 1)),
          allStatComponents: this.getSingleDashboardData.components.filter(
            (e) => e.type == "STAT"
          ),
          allCustomFilters: this.getSingleDashboardData.components
            .filter((e) => e.type == "CUSTOM_FILTER")
            .map((e) => {
              e.value = "";
              e.selected_filter = "";
              return e;
            }),
          keyValue: (Math.random() + 1).toString(36).substring(7),
          allowUserChangeOrder:
            this.getSingleDashboardData.allow_user_change_order,
        };
        const allEntities = [
          ...this.activeCustomDashboard.allTableComponents.flatMap(
            (e) => e.entity_id
          ),
          ...this.activeCustomDashboard.allStatComponents.flatMap(
            (e) => e.entity_id
          ),
          ...this.activeCustomDashboard.allCustomFilters.flatMap(
            (e) => e.entity_id
          ),
        ];
        this.activeCustomDashboard.allCustomFilters =
          this.activeCustomDashboard.allCustomFilters.filter(
            (e) => allEntities.indexOf(e.entity_id) !== -1
          );
        this.activeCustomDashboard.allTableComponents =
          this.activeCustomDashboard.allTableComponents.map((e) => {
            e.keyValue = (Math.random() + 1).toString(36).substring(7);
            return e;
          });
        this.activeCustomDashboard.allStatComponents =
          this.activeCustomDashboard.allStatComponents.map((e) => {
            // e.keyValue = (Math.random() + 1).toString(36).substring(7);
            return e;
          });
        this.activeCustomDashboard.allCustomFilters =
          this.activeCustomDashboard.allCustomFilters.map((e) => {
            e.keyValue = (Math.random() + 1).toString(36).substring(7);
            return e;
          });
        this.activeCustomDashboard.allTableComponents.forEach((el, i) => {
          if (el.is_expand) {
            this.expendedIndexs.push(i);
          }
        });
      }
      this.loading = false;
    },
    // changeCustomDashboard(index) {
    //     this.activeCustomDashboard = null;
    //     let allMainComponents = this.customDashboardsList[
    //         index
    //     ].components.filter(
    //         (e) => e.type == "TABLE" || e.type == "CHART" || e.type == "CALENDAR" || e.type == "LOGINS"
    //     );
    //     this.activeCustomDashboard = {
    //         name: this.customDashboardsList[index].name,
    //         includeGlobalDateFilter:
    //             this.customDashboardsList[index].include_global_date_filter,
    //         allTableComponents: allMainComponents.sort((a, b) =>
    //             a.order < b.order ? -1 : 1
    //         ),
    //         allStatComponents: this.customDashboardsList[index].components.filter(
    //             (e) => e.type == "STAT"
    //         ),
    //         allCustomFilters: this.customDashboardsList[index].components
    //             .filter((e) => e.type == "CUSTOM_FILTER")
    //             .map((e) => {
    //                 e.value = "";
    //                 e.selected_filter = "";
    //                 return e;
    //             }),
    //         keyValue: (Math.random() + 1).toString(36).substring(7),
    //         allowUserChangeOrder:
    //             this.customDashboardsList[index].allow_user_change_order,
    //     };
    //     const allEntities = [
    //         ...this.activeCustomDashboard.allTableComponents.flatMap(
    //             (e) => e.entity_id
    //         ),
    //         ...this.activeCustomDashboard.allStatComponents.flatMap(
    //             (e) => e.entity_id
    //         ),
    //         ...this.activeCustomDashboard.allCustomFilters.flatMap(
    //             (e) => e.entity_id
    //         ),
    //     ];
    //     this.activeCustomDashboard.allCustomFilters =
    //         this.activeCustomDashboard.allCustomFilters.filter(
    //             (e) => allEntities.indexOf(e.entity_id) !== -1
    //         );
    //     this.activeCustomDashboard.allTableComponents =
    //         this.activeCustomDashboard.allTableComponents.map((e) => {
    //             e.keyValue = (Math.random() + 1).toString(36).substring(7);
    //             return e;
    //         });
    //     this.activeCustomDashboard.allStatComponents =
    //         this.activeCustomDashboard.allStatComponents.map((e) => {
    //             // e.keyValue = (Math.random() + 1).toString(36).substring(7);
    //             return e;
    //         });
    //     this.activeCustomDashboard.allCustomFilters =
    //         this.activeCustomDashboard.allCustomFilters.map((e) => {
    //             e.keyValue = (Math.random() + 1).toString(36).substring(7);
    //             return e;
    //         });
    //     this.activeCustomDashboard.allTableComponents.forEach((el, i) => {
    //         if (el.is_expand) {
    //             this.expendedIndexs.push(i);
    //         }
    //     });
    //     this.activeCustomDashboardId = this.customDashboardsList[index]._id;
    // },
    changeDashboard(tab) {
      this.refresh = true;
      this.changeCustomDashboard(tab.index);
    },
  },
  watch: {
    "$route.params.dashboardId"() {
      this.getNecessaryInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
  min-width: 100%;
}
.dashboard {
  @media (max-width: 992px) {
    margin-top: 10px;
  }

  .stats-container {
    // height:85px;
    margin-bottom: 1.25em;
    // display: flex;
    gap: 30px;

    .card {
      margin-right: 5px;
      margin-bottom: 5px;
      width: 165px;
      height: 69px;
      border-radius: 10px;
      min-width: 170px;

      cursor: pointer;
      border-color: #e3eafd;

      &:hover {
        background-color: #f4f7ff;
      }

      &.active {
        background-color: #ebeffd;
        border-color: #d2dcfa;
      }
    }

    .latest-updates {
      font-size: 0.85em;
    }
  }

  .icon-block {
    display: flex;
    gap: 10px;

    .icon-file {
      max-width: 20px;
      display: inline;
    }
  }

  .search {
    border-radius: 5px;
    border: none !important;
    //box-shadow: 3px 3px 3px 0.5px rgba(0,0,0,0.1);
  }

  .vue-data-table-default {
    .title {
      letter-spacing: 0.019em;
    }

    .circle {
      position: relative;
      padding-left: 20px;
      text-transform: capitalize;
      font-size: 14px;

      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 50%;
      }

      &.completed::after {
        background: #08ad36;
      }

      &.draft::after {
        background: #f19203;
      }

      &.sent::after {
        background: #2f80ed;
      }

      &.active::after {
        background: #635e5e;
      }
    }
  }

  .notification-box {
    .list-group-flush > .list-group-item {
      border-width: 0 0 1px;
    }

    .noNotifications {
      margin-left: 40%;
      display: grid;
      gap: 0px;

      .img-text {
        margin-left: 5px;
        color: #1f1f1f;
      }
    }

    .notifcation-item {
      cursor: pointer;
      padding: 0;
      display: grid;
      grid-template-columns: 2.75em 1fr;
      gap: 15px;
      padding: 10px 0;

      .item-avatar {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #f754a2;
        background-color: rgba(#f754a2, $alpha: 0.1);
        color: #f754a2;
      }

      .item-body {
        .name {
          margin-bottom: 0;
        }
      }
    }
  }
}

.drag-grid {
  display: grid !important;
  grid-template-columns: 50% 50% !important;
  column-gap: 10px !important;
  row-gap: 1em !important;
  padding: 25px;
}
</style>
